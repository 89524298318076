<template>
  <div class="login-container">
    <div class="login-card">
      <div class="logo-container">
        <img src="https://conciel.com/images/CONCIEL_LOGO.png" alt="Conciel Logo" class="logo" />
      </div>

      <div class="brand-section">
        <h1>Welcome Back</h1>
        <p class="subtitle">Sign in to your Contromote account</p>
      </div>

      <form @submit.prevent="handleSubmit" class="login-form">
        <div class="form-group">
          <label for="email" class="form-label">
            <span class="label-text">Email Address</span>
            <span class="required">*</span>
          </label>
          <div class="input-container">
            <span class="input-icon">📧</span>
            <input 
              id="email"
              v-model.trim="username" 
              type="email" 
              required 
              autocomplete="email"
              placeholder="Enter your email address"
              :class="{ 'error': emailError }"
              @focus="emailError = false"
            />
          </div>
          <span v-if="emailError" class="field-error">Please enter a valid email address</span>
        </div>
        
        <div class="form-group">
          <label for="password" class="form-label">
            <span class="label-text">Password</span>
            <span class="required">*</span>
          </label>
          <div class="input-container">
            <span class="input-icon">🔒</span>
            <input 
              id="password"
              v-model="password" 
              :type="showPassword ? 'text' : 'password'" 
              required 
              autocomplete="current-password"
              placeholder="Enter your password"
              :class="{ 'error': passwordError }"
              @focus="passwordError = false"
            />
            <button 
              type="button" 
              class="toggle-password"
              @click="showPassword = !showPassword"
              :aria-label="showPassword ? 'Hide password' : 'Show password'"
            >
              <span class="icon">{{ showPassword ? '👁️' : '👁️‍🗨️' }}</span>
            </button>
          </div>
          <span v-if="passwordError" class="field-error">Password is required</span>
        </div>

        <div class="form-options">
          <label class="remember-me">
            <input 
              type="checkbox" 
              v-model="rememberMe"
              class="custom-checkbox"
            />
            <span class="checkbox-label">Keep me signed in</span>
          </label>
          <a href="#" class="forgot-password" @click.prevent="handleForgotPassword">
            Forgot password?
          </a>
        </div>

        <button 
          type="submit" 
          class="submit-button" 
          :disabled="loading || !isFormValid"
        >
          <span v-if="loading" class="loading-spinner"></span>
          <span class="button-text">{{ loading ? 'Signing in...' : 'Sign in' }}</span>
        </button>

        <div class="divider">
          <span>or</span>
        </div>

        <!-- <button 
          type="button" 
          class="google-signin-button"
          @click="handleGoogleSignIn"
        >
          <img src="@/assets/google-icon.svg" alt="" class="google-icon" />
          <span>Sign in with Google</span>
        </button> -->
      </form>

      <div v-if="error" class="error-message" role="alert">
        <span class="error-icon">⚠️</span>
        <span>{{ error }}</span>
      </div>

      <p class="signup-prompt">
        Don't have an account? 
        <a href="#" @click.prevent="handleSignUp">Sign up</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginForm',
  
  setup() {
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const error = ref(null);
    const loading = ref(false);
    const showPassword = ref(false);
    const rememberMe = ref(false);
    const emailError = ref(false);
    const passwordError = ref(false);

    const isFormValid = computed(() => {
      return username.value.includes('@') && password.value.length >= 6;
    });

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const handleSubmit = async () => {
      emailError.value = false;
      passwordError.value = false;
      error.value = null;

      if (!validateEmail(username.value)) {
        emailError.value = true;
        error.value = 'Please enter a valid email address';
        return;
      }

      if (password.value.length < 6) {
        passwordError.value = true;
        error.value = 'Password must be at least 6 characters';
        return;
      }

      loading.value = true;

      try {
        const response = await axios.post('https://iotinabox-api.mydevices.com/v1.0/oauth/token', 
          new URLSearchParams({
            grant_type: 'password',
            client_id: 'conciel',
            client_secret: '89bf4f64-232d-487b-8bd5-132691dda8a5',
            username: username.value,
            password: password.value,
          }),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        );

        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
          router.push('/dashboard');
          console.log('Login successful, token saved:', response.data.access_token);
          console.log('Token saved:', localStorage.getItem('token'));
        }
      } catch (err) {
        error.value = err.response?.data?.message || 'Login failed. Please check your credentials.';
        console.error('Login error:', err);
      } finally {
        loading.value = false;
      }
    };

    const handleForgotPassword = () => {
      console.log('Forgot password clicked');
    };

    const handleGoogleSignIn = () => {
      console.log('Google sign in clicked');
    };

    const handleSignUp = () => {
      console.log('Sign up clicked');
    };

    return {
      username,
      password,
      error,
      loading,
      showPassword,
      rememberMe,
      emailError,
      passwordError,
      isFormValid,
      handleSubmit,
      handleForgotPassword,
      handleGoogleSignIn,
      handleSignUp
    };
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 1.5rem;
}

.login-card {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 440px;
  transition: transform 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
}

.brand-section {
  text-align: center;
  margin-bottom: 2rem;
}

.logo-container {
  text-align: center;
  margin-bottom: 2rem;
}

.logo {
  max-width: 200px;
  height: auto;
}

h1 {
  color: #1a1a1a;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  font-size: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.label-text {
  color: #4a4a4a;
  font-size: 0.9rem;
  font-weight: 500;
}

.required {
  color: #dc3545;
  margin-left: 0.25rem;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 1rem;
  color: #666;
  font-size: 1rem;
}

input {
  width: 100%;
  padding: 0.875rem 1rem 0.875rem 2.5rem;
  border: 1.5px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

input:focus {
  outline: none;
  border-color: #4a90e2;
  background: white;
  box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.1);
}

input.error {
  border-color: #dc3545;
  background-color: #fff5f5;
}

.field-error {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: block;
}

.toggle-password {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  color: #666;
  transition: color 0.2s ease;
}

.toggle-password:hover {
  color: #4a90e2;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.custom-checkbox {
  width: 1.125rem;
  height: 1.125rem;
  margin: 0;
}

.checkbox-label {
  color: #666;
  font-size: 0.9rem;
}

.forgot-password {
  color: #4a90e2;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.forgot-password:hover {
  color: #357abd;
  text-decoration: underline;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.submit-button:hover:not(:disabled) {
  background-color: #357abd;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

.submit-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
  transform: none;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.divider {
  text-align: center;
  margin: 1.5rem 0;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #e1e1e1;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background-color: white;
  padding: 0 1rem;
  color: #666;
  font-size: 0.9rem;
}

.google-signin-button {
  width: 100%;
  padding: 0.875rem;
  background-color: white;
  border: 1.5px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #4a4a4a;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.google-signin-button:hover {
  background-color: #f8f9fa;
  border-color: #d1d1d1;
}

.google-icon {
  width: 20px;
  height: 20px;
}

.error-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff5f5;
  border: 1px solid #ffcdd2;
  border-radius: 10px;
  color: #dc3545;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.signup-prompt {
  text-align: center;
  margin-top: 1.5rem;
  color: #666;
  font-size: 0.9rem;
}

.signup-prompt a {
  color: #4a90e2;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.signup-prompt a:hover {
  color: #357abd;
  text-decoration: underline;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .login-card {
    padding: 1.5rem;
  }
  
  h1 {
    font-size: 1.75rem;
  }
  
  .form-options {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}
</style>
  