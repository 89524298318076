<template>
  <div class="add-company-container">
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm sticky-top">
      <div class="container-fluid">
        <span class="navbar-brand d-flex align-items-center">
          <img src="https://conciel.com/images/CONCIEL_LOGO.png" alt="Conciel Logo" class="navbar-logo me-3" />
          <i class="bi bi-building-fill me-2"></i>
          Add New Company
        </span>
        <button @click="goBack" class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left me-2"></i>
          Back to Dashboard
        </button>
      </div>
    </nav>

    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card shadow-sm">
            <div class="card-body p-4">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="card-title mb-0">Company Information</h4>
              </div>
              
              <div v-if="error" class="alert alert-danger mb-4">
                {{ error }}
              </div>

              <form @submit.prevent="submitForm">
                <div class="mb-3">
                  <label class="form-label">Company Name*</label>
                  <input 
                    v-model="formData.name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>

                <div class="mb-3">
                  <label class="form-label">Address*</label>
                  <input 
                    v-model="formData.address"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <label class="form-label">Country*</label>
                    <select 
                      v-model="formData.country"
                      class="form-select"
                      required
                    >
                      <option value="">Select a country</option>
                      <option 
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">State*</label>
                    <select 
                      v-model="formData.state"
                      class="form-select"
                      required
                      :disabled="!formData.country"
                    >
                      <option value="">Select a state</option>
                      <option 
                        v-for="state in states"
                        :key="state.code"
                        :value="state.code"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <label class="form-label">City*</label>
                    <select 
                      v-model="formData.city"
                      class="form-select"
                      required
                      :disabled="!formData.state"
                    >
                      <option value="">Select a city</option>
                      <option 
                        v-for="city in cities"
                        :key="city.name"
                        :value="city.name"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">ZIP Code*</label>
                    <input 
                      v-model="formData.zip"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                </div>

                <div class="d-grid gap-2">
                  <button 
                    type="submit" 
                    class="btn btn-danger"
                    :disabled="isSubmitting"
                  >
                    <span v-if="isSubmitting" class="spinner-border spinner-border-sm me-2" role="status"></span>
                    <i v-else class="bi bi-building-add me-2"></i>
                    {{ isSubmitting ? 'Creating...' : 'Create Company' }}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="card shadow-sm mt-4" v-if="showLocationForm">
            <div class="card-body p-4">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="card-title mb-0">Add Location</h4>
                <button type="button" class="btn btn-outline-danger btn-sm" @click="removeLocationForm">
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>

              <div class="alert alert-info mb-4">
                <i class="bi bi-info-circle me-2"></i>
                This location will be created automatically after the company is created.
              </div>

              <form @submit.prevent="submitLocationForm">
                <div class="mb-3">
                  <label class="form-label">Location Name*</label>
                  <input 
                    v-model="locationData.name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>

                <div class="mb-3">
                  <label class="form-label">Address*</label>
                  <input 
                    v-model="locationData.address"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <label class="form-label">Country*</label>
                    <select 
                      v-model="locationData.country"
                      class="form-select"
                      required
                    >
                      <option value="">Select a country</option>
                      <option 
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">State*</label>
                    <select 
                      v-model="locationData.state"
                      class="form-select"
                      required
                      :disabled="!locationData.country"
                    >
                      <option value="">Select a state</option>
                      <option 
                        v-for="state in locationStates"
                        :key="state.code"
                        :value="state.code"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <label class="form-label">City*</label>
                    <select 
                      v-model="locationData.city"
                      class="form-select"
                      required
                      :disabled="!locationData.state"
                    >
                      <option value="">Select a city</option>
                      <option 
                        v-for="city in locationCities"
                        :key="city.name"
                        :value="city.name"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">ZIP Code*</label>
                    <input 
                      v-model="locationData.zip"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                </div>

                <div class="d-grid gap-2">
                  <button 
                    type="submit" 
                    class="btn btn-danger"
                    :disabled="isSubmittingLocation"
                  >
                    <span v-if="isSubmittingLocation" class="spinner-border spinner-border-sm me-2" role="status"></span>
                    <i v-else class="bi bi-plus-circle me-2"></i>
                    {{ isSubmittingLocation ? 'Adding...' : 'Add Location' }}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="d-grid gap-2 mt-4">
            <button 
              type="button" 
              class="btn btn-outline-danger"
              @click="showLocationForm = true"
              v-if="!showLocationForm"
            >
              <i class="bi bi-plus-circle me-2"></i>
              Add Location
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { Country, State, City } from 'country-state-city'

export default {
  name: 'AddCompany',
  
  setup() {
    const router = useRouter()
    const error = ref(null)
    const isSubmitting = ref(false)
    const countries = ref([])
    const states = ref([])
    const cities = ref([])
    
    const formData = ref({
      name: '',
      industry: '["IoT"]',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    })

    const fetchCountries = () => {
      countries.value = Country.getAllCountries()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(country => ({
          code: country.isoCode,
          name: country.name
        }))
    }

    watch(() => formData.value.country, (newCountry) => {
      if (newCountry) {
        states.value = State.getStatesOfCountry(newCountry)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(state => ({
            code: state.isoCode,
            name: state.name
          }))
      } else {
        states.value = []
      }
      formData.value.state = ''
      formData.value.city = ''
      cities.value = []
    })

    watch(() => formData.value.state, (newState) => {
      if (newState && formData.value.country) {
        cities.value = City.getCitiesOfState(formData.value.country, newState)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(city => ({
            name: city.name
          }))
      } else {
        cities.value = []
      }
      formData.value.city = ''
    })

    onMounted(() => {
      fetchCountries()
    })

    const companyId = ref(null)

    const submitForm = async () => {
      try {
        error.value = null
        isSubmitting.value = true

        const token = localStorage.getItem('token')
        const response = await axios.post('https://iotinabox-api.mydevices.com/companies', formData.value, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })

        console.log('Company created:', response.data)
        companyId.value = response.data.id

        if (showLocationForm.value) {
          await new Promise(resolve => setTimeout(resolve, 3000))
          await submitLocation(response.data.id)
        }

        router.push('/dashboard')
      } catch (err) {
        console.error('Error creating company:', err)
        error.value = err.response?.data?.message || 'Failed to create company. Please try again.'
      } finally {
        isSubmitting.value = false
      }
    }

    const submitLocation = async (companyId) => {
      try {
        isSubmittingLocation.value = true
        const token = localStorage.getItem('token')
        
        locationData.value.company_id = companyId
        locationData.value.parent_id = companyId
        
        const response = await axios.post(
          `https://iotinabox-api.mydevices.com/companies/${companyId}/locations`, 
          locationData.value,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )

        console.log('Location added:', response.data)
      } catch (err) {
        console.error('Error adding location:', err)
        error.value = err.response?.data?.message || 'Failed to add location. Please try again.'
        throw err
      } finally {
        isSubmittingLocation.value = false
      }
    }

    const goBack = () => {
      router.push('/dashboard')
    }

    const showLocationForm = ref(false)
    const isSubmittingLocation = ref(false)
    const locationStates = ref([])
    const locationCities = ref([])
    
    const locationData = ref({
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      industry: '["IoT"]',
      primary_users: ["Elias"],
      supported_device: 'Default',
      external_id: '1000', // You might want to generate this
      parent_id: null // This will be set after company creation
    })

    // Watch for location country/state changes
    watch(() => locationData.value.country, (newCountry) => {
      if (newCountry) {
        locationStates.value = State.getStatesOfCountry(newCountry)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(state => ({
            code: state.isoCode,
            name: state.name
          }))
      } else {
        locationStates.value = []
      }
      locationData.value.state = ''
      locationData.value.city = ''
      locationCities.value = []
    })

    watch(() => locationData.value.state, (newState) => {
      if (newState && locationData.value.country) {
        locationCities.value = City.getCitiesOfState(locationData.value.country, newState)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(city => ({
            name: city.name
          }))
      } else {
        locationCities.value = []
      }
      locationData.value.city = ''
    })

    const removeLocationForm = () => {
      showLocationForm.value = false
      locationData.value = {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        industry: '["IoT"]',
        primary_users: [[]],
        supported_device: 'Default',
        external_id: '',
        parent_id: null
      }
    }

    return {
      formData,
      submitForm,
      goBack,
      error,
      isSubmitting,
      countries,
      states,
      cities,
      showLocationForm,
      locationData,
      isSubmittingLocation,
      locationStates,
      locationCities,
      companyId,
      removeLocationForm
    }
  }
}
</script>

<style scoped>
.add-company-container {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.navbar {
  padding: 1rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95)) !important;
  backdrop-filter: blur(10px);
}

.navbar-logo {
  height: 35px;
  width: auto;
}

.card {
  border: none;
  border-radius: 1rem;
}

.card-body {
  padding: 2rem !important;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #212529;
}

.form-control, .form-select {
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control:focus, .form-select:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.btn-danger {
  padding: 0.75rem;
  font-weight: 500;
  background: linear-gradient(to right, #dc3545, #c82333);
  border: none;
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2);
}

.btn-danger:hover {
  background: linear-gradient(to right, #c82333, #bd2130);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(220, 53, 69, 0.3);
}

.btn-outline-danger {
  padding: 0.75rem;
  font-weight: 500;
}

.alert {
  border-radius: 0.5rem;
  border: none;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.form-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.5rem;
}

.form-label::after {
  content: "*";
  color: #dc3545;
  margin-left: 4px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}
</style> 