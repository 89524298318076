import { createRouter, createWebHistory } from 'vue-router'
import LoginForm from '@/components/LoginForm.vue'
import CompanyDashboard from '@/components/CompanyDashboard.vue'
import AddCompany from '../components/AddCompany.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginForm
    },
    {
      path: '/dashboard',
      name: 'CompanyDashboard',
      component: CompanyDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/add-company',
      name: 'AddCompany',
      component: AddCompany,
      meta: { requiresAuth: true }
    },
    {
      path: '/edit-company/:id',
      name: 'EditCompany',
      component: () => import('../components/EditCompany.vue'),
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  console.log('Navigation guard:', {
    to: to.path,
    requiresAuth: to.meta.requiresAuth,
    hasToken: !!localStorage.getItem('token')
  });
  
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    next('/login');
  } else {
    next();
  }
})

export default router
