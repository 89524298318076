<template>
  <div class="dashboard-container">
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm sticky-top">
      <div class="container-fluid">
        <span class="navbar-brand d-flex align-items-center">
          <img src="https://conciel.com/images/CONCIEL_LOGO.png" alt="Conciel Logo" class="navbar-logo me-3" />
          <i class="bi bi-building-fill me-2"></i>
          Companies Overview
        </span>
        <div class="d-flex align-items-center gap-3">
          <div class="company-count">
            <span class="badge bg-primary">
              {{ companiesList.length }} Companies
            </span>
          </div>
          <button @click="addCompany" class="btn btn-danger me-2">
            <i class="bi bi-plus-circle me-2"></i>
            Add Company
          </button>
          <button @click="logout" class="btn btn-outline-danger">
            <i class="bi bi-box-arrow-right me-2"></i>
            Logout
          </button>
        </div>
      </div>
    </nav>

    <div class="dashboard-content">
      <div class="container-fluid">
        <div class="row">
          <!-- Map Section -->
          <div class="col-lg-5 map-container">
            <div class="card h-100 shadow-sm">
              <div class="card-body p-0">
                <div id="map" ref="mapContainer" class="map"></div>
              </div>
            </div>
          </div>

          <!-- Companies List Section -->
          <div class="col-lg-7">
            <div class="companies-container">
              <div v-if="isLoading" class="text-center py-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p class="mt-3">Loading companies...</p>
              </div>

              <div v-else-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>

              <div v-else class="row g-4">
                <div v-for="company in companiesList" 
                     :key="company.id" 
                     class="col-12"
                     @mouseover="highlightMarker(company)"
                     @mouseleave="unhighlightMarker(company)">
                  <div class="card h-100 shadow-sm hover-shadow">
                    <div class="card-header bg-white border-bottom">
                      <div class="d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">
                          <i class="bi bi-building me-2 text-primary"></i>
                          {{ company.name }}
                        </h5>
                        <div class="d-flex align-items-center gap-2">
                          <span class="badge bg-secondary">#{{ company.id }}</span>
                          <button 
                            @click.stop="editCompany(company)"
                            class="btn btn-outline-primary btn-sm me-2"
                            title="Edit Company"
                          >
                            <i class="bi bi-pencil me-1"></i>
                            Edit
                          </button>
                          <button 
                            @click.stop="deleteCompany(company)"
                            class="btn btn-outline-danger btn-sm"
                            title="Delete Company"
                          >
                            <i class="bi bi-trash me-1"></i>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card-body">
                      <div class="mb-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <div class="d-flex align-items-center">
                            <i class="bi bi-geo-alt-fill text-primary me-2"></i>
                            <h6 class="mb-0">Location</h6>
                          </div>
                          <button 
                            v-if="company.longitude && company.latitude"
                            @click="flyToLocation(company)"
                            class="btn btn-sm btn-outline-primary"
                            title="View on map"
                          >
                            <i class="bi bi-globe2">Get There</i>
                          </button>
                        </div>
                        <p class="text-muted mb-0">
                          {{ company.city }}, {{ company.country }}
                        </p>
                      </div>

                      <div class="mb-3">
                        <div class="d-flex align-items-center mb-2">
                          <i class="bi bi-clock text-primary me-2"></i>
                          <h6 class="mb-0">Timezone</h6>
                        </div>
                        <p class="text-muted mb-0">{{ company.timezone }}</p>
                      </div>

                      <div class="mb-3">
                        <div class="d-flex align-items-center mb-2">
                          <i class="bi bi-calendar3 text-primary me-2"></i>
                          <h6 class="mb-0">Created</h6>
                        </div>
                        <p class="text-muted mb-0">{{ formatDate(company.created_at) }}</p>
                      </div>

                      <div class="mb-3">
                        <div class="d-flex align-items-center mb-2">
                          <i class="bi bi-building text-primary me-2"></i>
                          <h6 class="mb-0">Address</h6>
                        </div>
                        <p class="text-muted mb-0">{{ company.address }}</p>
                      </div>

                      <div class="mb-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <div class="d-flex align-items-center">
                            <i class="bi bi-pin-map-fill text-primary me-2"></i>
                            <h6 class="mb-0">Locations ({{ company.locations?.length || 0 }})</h6>
                          </div>
                          <button 
                            @click="toggleLocations(company)"
                            class="btn btn-sm btn-outline-secondary"
                            :class="{ 'active': company.showLocations }"
                          >
                            <i class="bi" :class="company.showLocations ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                            {{ company.showLocations ? 'Hide Locations' : 'Show Locations' }}
                          </button>
                        </div>
                        
                        <div v-if="company.showLocations" class="locations-list">
                          <div v-if="company.loadingLocations" class="text-center py-3">
                            <div class="spinner-border spinner-border-sm text-primary" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                            <p class="text-muted mb-0 mt-2">Loading locations...</p>
                          </div>
                          
                          <div v-else-if="company.locationError" class="alert alert-danger">
                            {{ company.locationError }}
                          </div>
                          
                          <div v-else-if="company.locations?.length === 0" class="text-center py-3">
                            <i class="bi bi-geo-alt text-muted fs-4"></i>
                            <p class="text-muted mb-0 mt-2">No locations found</p>
                          </div>
                          
                          <div v-else class="location-items">
                            <div v-for="location in company.locations" 
                                 :key="location.id"
                                 class="location-item">
                              <div class="location-header">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div class="location-info">
                                    <h5 class="location-name">{{ location.name }}</h5>
                                    <p class="location-address">
                                      <i class="bi bi-geo-alt-fill text-primary me-1"></i>
                                      {{ location.address }}
                                    </p>
                                  </div>
                                  <button 
                                    v-if="location.latitude && location.longitude"
                                    @click="flyToLocation(location)"
                                    class="btn btn-outline-primary btn-sm"
                                    title="View on map"
                                  >
                                    <i class="bi bi-geo-alt me-1"></i>
                                    Map
                                  </button>
                                </div>
                              </div>

                              <div class="location-content mt-3">
                                <button 
                                  @click="toggleThings(location)"
                                  class="btn btn-outline-secondary btn-sm d-flex align-items-center"
                                  :class="{ 'active': location.showThings }"
                                >
                                  <i class="bi" :class="location.showThings ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                                  <span class="ms-1">Things ({{ location.things?.length || 0 }})</span>
                                </button>
                                
                                <div v-if="location.showThings" class="things-container mt-3">
                                  <div v-if="location.loadingThings" class="text-center py-2">
                                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                                      <span class="visually-hidden">Loading...</span>
                                    </div>
                                  </div>
                                  
                                  <div v-else-if="location.thingsError" class="alert alert-danger">
                                    {{ location.thingsError }}
                                  </div>
                                  
                                  <div v-else-if="location.things?.length === 0" class="text-center py-2">
                                    <p class="text-muted mb-0">No things found</p>
                                  </div>
                                  
                                  <div v-else class="things-list">
                                    <div v-for="thing in location.things" 
                                         :key="thing.id" 
                                         class="thing-item"
                                         @click="fetchThingHistory(thing, location)">
                                      <div class="d-flex align-items-center gap-2">
                                        <i class="bi bi-cpu text-primary"></i>
                                        <div class="thing-info">
                                          <div class="thing-name">{{ thing.thing_name }}</div>
                                          <div class="thing-details">
                                            <span class="badge bg-light text-dark me-2">
                                              <i class="bi bi-tag-fill me-1"></i>
                                              {{ thing.sensor_type }}
                                            </span>
                                            <span class="badge bg-light text-dark">
                                              <i class="bi bi-gear-fill me-1"></i>
                                              {{ thing.sensor_use }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineController
} from 'chart.js'
import { Modal } from 'bootstrap'
import 'chartjs-adapter-date-fns'
import { enUS } from 'date-fns/locale'

// Register components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineController
)

export default {
  name: 'CompanyDashboard',
  
  setup() {
    const router = useRouter()
    const companiesList = ref([])
    const isLoading = ref(true)
    const errorMessage = ref(null)
    const mapContainer = ref(null)
    const map = ref(null)
    const markers = ref({})
    const locationMarkers = ref({})

    // Replace with your Mapbox access token
    mapboxgl.accessToken = 'pk.eyJ1IjoiZWdtYWFsb3VmIiwiYSI6ImNsdGxpODRoMjBxYXQyam9laGRkZHFiNmcifQ.ELFeubmt52jAGCLWO9Vi9Q'

    const initializeMap = () => {
      map.value = new mapboxgl.Map({
        container: mapContainer.value,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [0, 20], // Default center
        zoom: 1.5
      })

      map.value.addControl(new mapboxgl.NavigationControl())
    }

    const addMarkers = () => {
      companiesList.value.forEach(company => {
        if (company.longitude && company.latitude) {
          const marker = new mapboxgl.Marker()
            .setLngLat([company.longitude, company.latitude])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 })
                .setHTML(
                  `<strong>${company.name}</strong><br>
                   ${company.city}, ${company.country}`
                )
            )
            .addTo(map.value)
          
          markers.value[company.id] = marker
        }
      })
    }

    const highlightMarker = (company) => {
      const marker = markers.value[company.id]
      if (marker) {
        marker.getElement().style.zIndex = 1
        marker.togglePopup()
      }
    }

    const unhighlightMarker = (company) => {
      const marker = markers.value[company.id]
      if (marker) {
        marker.getElement().style.zIndex = 0
        marker.togglePopup()
      }
    }

    const fetchCompanies = async () => {
      const token = localStorage.getItem('token')
      
      if (!token) {
        router.push('/login')
        return
      }

      try {
        const response = await axios.get('https://iotinabox-api.mydevices.com/companies', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        companiesList.value = response.data
        
        // Initialize map after getting companies
        initializeMap()
        addMarkers()
      } catch (err) {
        console.error('Error fetching companies:', err.response || err)
        errorMessage.value = err.response?.data?.message || 'Failed to load companies. Please try again later.'
      } finally {
        isLoading.value = false
      }
    }

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A'
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }

    const logout = () => {
      localStorage.removeItem('token')
      router.push('/login')
    }


    const fetchLocations = async (company) => {
      if (company.locations) return; // Already fetched
      
      company.loadingLocations = true;
      company.showLocations = true;
      
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://iotinabox-api.mydevices.com/companies/${company.id}/locations`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        
        company.locations = response.data;
        
        // Add markers for locations if they have coordinates
        company.locations.forEach(location => {
          if (location.latitude && location.longitude) {
            const marker = new mapboxgl.Marker({
              color: '#FF0000' // Different color for location markers
            })
              .setLngLat([location.longitude, location.latitude])
              .setPopup(
                new mapboxgl.Popup({ offset: 25 })
                  .setHTML(`
                    <strong>${location.name}</strong><br>
                    ${location.address}
                  `)
              )
              .addTo(map.value);
            
            // Store marker reference
            if (!locationMarkers.value[company.id]) {
              locationMarkers.value[company.id] = [];
            }
            locationMarkers.value[company.id].push(marker);
          }
        });
        
      } catch (err) {
        console.error('Error fetching locations:', err);
        company.locationError = 'Failed to load locations';
      } finally {
        company.loadingLocations = false;
      }
    };

    const toggleLocations = async (company) => {
      if (!company.locations) {
        await fetchLocations(company);
      } else {
        company.showLocations = !company.showLocations;
        
        // Show/hide markers
        if (locationMarkers.value[company.id]) {
          locationMarkers.value[company.id].forEach(marker => {
            marker.getElement().style.display = company.showLocations ? 'block' : 'none';
          });
        }
      }
    };

    const flyToLocation = (location) => {
      if (map.value && location.longitude && location.latitude) {
        map.value.flyTo({
          center: [location.longitude, location.latitude],
          zoom: 15,
          essential: true,
          duration: 2000
        });
      }
    };

    const fetchThings = async (location) => {
      if (location.things) return;
      
      location.loadingThings = true;
      location.showThings = true;
      
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://iotinabox-api.mydevices.com/companies/${location.company_id}/locations/${location.id}/things`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        
        console.log('Things response:', response.data);
        location.things = response.data;
      } catch (err) {
        console.error('Error details:', {
          status: err.response?.status,
          data: err.response?.data,
          message: err.message
        });
        location.thingsError = 'Failed to load things';
      } finally {
        location.loadingThings = false;
      }
    };

    const toggleThings = async (location) => {
      if (!location.things) {
        await fetchThings(location);
      } else {
        location.showThings = !location.showThings;
      }
    };

    const fetchThingHistory = async (thing, location) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://iotinabox-api.mydevices.com/companies/${location.company_id}/locations/${location.id}/things/${thing.id}/history?type=6month`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        
        console.log('API Response:', response.data); // Debug log

        // Process the data
        const readings = response.data.readings;
        const channel3Data = readings.map(reading => ({
          x: new Date(reading.ts),
          y: reading.sensors.find(sensor => sensor.channel === "3")?.v || null
        })).filter(point => point.y !== null);

        console.log('Processed Data:', channel3Data); // Debug log

        // Create or update modal with chart
        const modalId = `chart-modal-${thing.id}`;
        let modalElement = document.getElementById(modalId);
        
        // Remove existing modal if it exists
        if (modalElement) {
          modalElement.remove();
        }

        // Create new modal
        modalElement = document.createElement('div');
        modalElement.id = modalId;
        modalElement.className = 'modal fade';
        modalElement.innerHTML = `
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">${thing.thing_name} - Temperature History</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div class="modal-body">
                <canvas id="chart-${thing.id}"></canvas>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(modalElement);

        // Show modal
        const modal = new Modal(modalElement);
        modal.show();

        // Wait for modal animation
        setTimeout(() => {
          const ctx = document.getElementById(`chart-${thing.id}`);
          if (!ctx) {
            console.error('Canvas element not found!');
            return;
          }

          // Destroy ALL existing charts
          if (window.thingCharts) {
            Object.values(window.thingCharts).forEach(chart => {
              if (chart) {
                chart.destroy();
              }
            });
          }
          window.thingCharts = {};

          // Create new chart
          window.thingCharts[thing.id] = new Chart(ctx, {
            type: 'line',
            data: {
              datasets: [{
                label: 'Temperature (°C)',
                data: channel3Data,
                borderColor: '#4a90e2',
                tension: 0.1,
                pointRadius: 2,
                borderWidth: 2
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  mode: 'index',
                  intersect: false,
                }
              },
              scales: {
                x: {
                  type: 'time',
                  adapters: {
                    date: {
                      locale: enUS
                    }
                  },
                  time: {
                    unit: 'day',
                    displayFormats: {
                      day: 'MMM d, yyyy'
                    }
                  },
                  title: {
                    display: true,
                    text: 'Date'
                  }
                },
                y: {
                  type: 'linear',
                  title: {
                    display: true,
                    text: 'Temperature (°C)'
                  },
                  beginAtZero: false
                }
              },
              interaction: {
                intersect: false,
                mode: 'index'
              }
            }
          });

          // Add cleanup listener
          modalElement.addEventListener('hidden.bs.modal', () => {
            if (window.thingCharts[thing.id]) {
              window.thingCharts[thing.id].destroy();
              delete window.thingCharts[thing.id];
            }
            modalElement.remove();
          });
        }, 150);

      } catch (err) {
        console.error('Error fetching thing history:', err);
        console.error('Error details:', {
          status: err.response?.status,
          data: err.response?.data,
          message: err.message
        });
      }
    };

    const addCompany = () => {
      router.push('/add-company')
    };

    const deleteCompany = async (company) => {
      if (!confirm(`Are you sure you want to delete ${company.name}?`)) {
        return;
      }

      try {
        const token = localStorage.getItem('token');
        await axios.delete(`https://iotinabox-api.mydevices.com/companies/${company.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        // Remove company from list
        companiesList.value = companiesList.value.filter(c => c.id !== company.id);
        
        // Remove markers if they exist
        if (markers.value[company.id]) {
          markers.value[company.id].remove();
          delete markers.value[company.id];
        }
        
        // Remove location markers if they exist
        if (locationMarkers.value[company.id]) {
          locationMarkers.value[company.id].forEach(marker => marker.remove());
          delete locationMarkers.value[company.id];
        }

      } catch (err) {
        console.error('Error deleting company:', err);
        alert(err.response?.data?.message || 'Failed to delete company. Please try again.');
      }
    };

    const editCompany = (company) => {
      router.push(`/edit-company/${company.id}`);
    };

    onMounted(() => {
      fetchCompanies()
    })

    onUnmounted(() => {
      if (map.value) {
        map.value.remove()
      }
      Object.values(locationMarkers.value).forEach(markers => {
        markers.forEach(marker => marker.remove());
      });
    })

    return {
      companiesList,
      isLoading,
      errorMessage,
      formatDate,
      logout,
      addCompany,
      mapContainer,
      highlightMarker,
      unhighlightMarker,
      flyToLocation,
      toggleLocations,
      toggleThings,
      fetchThingHistory,
      deleteCompany,
      editCompany
    }
  }
}
</script>

<style scoped>
@import 'mapbox-gl/dist/mapbox-gl.css';

.dashboard-container {
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.dashboard-content {
  padding: 2rem 0;
}

.map-container {
  position: sticky;
  top: 80px;
  height: calc(100vh - 100px);
}

.map {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.companies-container {
  padding: 0 1rem;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.hover-shadow {
  transition: all 0.3s ease;
}

.hover-shadow:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-color: #4a90e2;
}

.card {
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 1.5rem; /* Add spacing between cards */
}

.card:hover {
  border-color: #4a90e2;
}

.card-header {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.navbar {
  padding: 1rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95)) !important;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar-brand {
  font-weight: 700;
  font-size: 1.25rem;
  color: #1a1a1a;
}

.btn {
  font-weight: 500;
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.btn-outline-primary {
  border-width: 2px;
}

.btn-outline-primary:hover {
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

.badge {
  font-weight: 600;
  padding: 0.6em 1em;
  border-radius: 20px;
  letter-spacing: 0.3px;
}

.badge.bg-primary {
  background: linear-gradient(135deg, #4a90e2, #357abd) !important;
  box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
}

.text-muted {
  color: #6c757d !important;
}

/* Custom scrollbar */
.companies-container::-webkit-scrollbar {
  width: 8px;
}

.companies-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.companies-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.companies-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 992px) {
  .map-container {
    position: relative;
    top: 0;
    height: 400px;
    margin-bottom: 2rem;
  }

  .companies-container {
    max-height: none;
    overflow-y: visible;
  }
}

/* Add these styles for custom markers and popups */
.custom-marker {
  color: #4a90e2;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-marker:hover {
  color: #357abd;
  transform: scale(1.2);
}

:global(.custom-popup) {
  max-width: 200px;
}

:global(.custom-popup .mapboxgl-popup-content) {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

:global(.popup-content h6) {
  margin: 0 0 5px 0;
  color: #333;
}

:global(.popup-content p) {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.locations-list {
  margin-top: 1rem;
  border-radius: 0.75rem;
  background-color: #f8f9fa;
  padding: 1.5rem;
}

.location-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.location-item {
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}

.location-item:hover {
  border-color: #4a90e2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.location-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
}

.location-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.location-address {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0;
}

.location-content {
  padding-top: 0.5rem;
}

.things-container {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
}

.things-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.thing-item {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
}

.thing-item:hover {
  transform: translateX(4px);
  border-color: #4a90e2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.thing-info {
  flex: 1;
}

.thing-name {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.25rem;
}

.thing-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.badge {
  font-weight: 500;
  padding: 0.5em 0.75em;
  border-radius: 0.375rem;
}

/* Button improvements */
.btn-outline-secondary {
  border-color: #dee2e6;
  color: #6c757d;
}

.btn-outline-secondary:hover,
.btn-outline-secondary.active {
  background-color: #f8f9fa;
  border-color: #4a90e2;
  color: #4a90e2;
}

/* Loading states */
.spinner-border-sm {
  width: 1.2rem;
  height: 1.2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .locations-list {
    padding: 1rem;
  }
  
  .location-item {
    padding: 1rem;
  }
  
  .thing-item {
    padding: 0.75rem;
  }
}
</style> 